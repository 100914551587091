<template>
  <div class="home-image-detail">
    <div id="signal" class="signal-image" @mousewheel="bbimg($event)">
      <img id="img" border="0" :src="imgSrc" />
    </div>
    <div class="drawer-detail">
      <detail-dialog :infoData="dataInfo" :show="true"></detail-dialog>
    </div>
  </div>
</template>

<script>
import detailDialog from "@/components/detailDialog/index";
export default {
  components: {
    detailDialog,
  },
  data() {
    return {
      SUCCESS_CODE: 0,
      params: {
        zoomVal: 1,
        left: 0,
        top: 0,
        currentX: 0,
        currentY: 0,
        flag: false,
      },
      imgSrc: "",
      dataInfo: {},
    };
  },
  created() {
    let id = this.$route.params.id;
    this.getImageInfo(id);
  },
  mounted() {
    this.startDrag(
      document.getElementById("img"),
      document.getElementById("img")
    );
  },
  methods: {
    // 获取图片详情
    getImageInfo(id) {
      this.isLoading = true;
      this.$http
        .get("/resource-info/" + id)
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            if (res.data) {
              this.dataInfo = res.data.resourceInfo;
              this.imgSrc = this.dataInfo.obsUrl;
            } else {
              this.dataInfo = {};
            }
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.dataInfo = {};
          this.isLoading = false;
        });
    },
    bbimg(event) {
      //   var o = document.getElementsByTagName("img")[0];
      var o = document.getElementById("img");
      this.params.zoomVal += event.wheelDelta / 1200;
      if (this.params.zoomVal >= 0.2) {
        o.style.transform = "scale(" + this.params.zoomVal + ")";
      } else {
        this.params.zoomVal = 0.2;
        o.style.transform = "scale(" + this.params.zoomVal + ")";
        return false;
      }
    },
    //获取相关CSS属性
    getCss(o, key) {
      return o.currentStyle
        ? o.currentStyle[key]
        : document.defaultView.getComputedStyle(o, false)[key];
    },
    //拖拽的实现
    startDrag(bar, target, callback) {
      let self = this;
      if (this.getCss(target, "left") !== "auto") {
        this.params.left = this.getCss(target, "left");
      }
      if (this.getCss(target, "top") !== "auto") {
        this.params.top = this.getCss(target, "top");
      }
      //o是移动对象
      bar.onmousedown = function (event) {
        self.params.flag = true;
        if (!event) {
          event = window.event;
          //防止IE文字选中
          bar.onselectstart = function () {
            return false;
          };
        }
        var e = event;
        self.params.currentX = e.clientX;
        self.params.currentY = e.clientY;
      };
      document.onmouseup = function () {
        self.params.flag = false;
        if (self.getCss(target, "left") !== "auto") {
          self.params.left = self.getCss(target, "left");
        }
        if (self.getCss(target, "top") !== "auto") {
          self.params.top = self.getCss(target, "top");
        }
      };
      document.onmousemove = function (event) {
        var e = event ? event : window.event;
        if (self.params.flag) {
          var nowX = e.clientX,
            nowY = e.clientY;
          var disX = nowX - self.params.currentX,
            disY = nowY - self.params.currentY;
          target.style.left = parseInt(self.params.left) + disX + "px";
          target.style.top = parseInt(self.params.top) + disY + "px";
          if (typeof callback == "function") {
            callback(
              (parseInt(params.left) || 0) + disX,
              (parseInt(params.top) || 0) + disY
            );
          }
          if (event.preventDefault) {
            event.preventDefault();
          }
          return false;
        }
      };
    },
  },
};
</script>

<style lang="less">
.home-image-detail {
  height: 100vh;
  padding-right: 260px;
  position: relative;
  .signal-image {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    padding: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
    > img {
      position: absolute;
      // max-height: 100%;
      max-width: 100%;
      cursor: move;
    }
  }
  .drawer-detail {
    position: absolute;
    width: 240px;
    height: 100%;
    right: 0;
    top: 0;
  }
}
</style>